import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Roboto } from "next/font/google";
import Head from "next/head";
import { AuthUserProvider } from "../context/AuthUserProvider"; // Import the CSS
import "./global.css";
config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

const roboto = Roboto({
  subsets: ["latin"],
  weight: ["400", "500"],
});

export default function App({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Create Next App</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <AuthUserProvider>
        <main className={`${roboto.className} m-0 h-full w-full bg-black overflow-x-hidden`}>
          <Component {...pageProps} />
        </main>
      </AuthUserProvider>
    </>
  );
}
