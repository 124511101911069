import { initializeApp } from "firebase/app";
import { getFirestore, Firestore } from "firebase/firestore";
import { getStorage, FirebaseStorage } from "firebase/storage";
import { getAuth, Auth } from "firebase/auth";
var firebaseConfig = {
  apiKey: "AIzaSyDSc2DQ0GNa0ce-60T22ZFaUMceYgwrOh0",
  authDomain: "amvgen-website.firebaseapp.com",
  projectId: "amvgen-website",
  storageBucket: "amvgen-website.appspot.com",
  messagingSenderId: "431794016623",
  appId: "1:431794016623:web:59b8af0ae30ae6ebbbd89a",
  measurementId: "G-KS6M34864T",
};

const app = initializeApp(firebaseConfig);

var db: Firestore, storage: FirebaseStorage, auth: Auth;

db = getFirestore(app);
storage = getStorage(app);
auth = getAuth(app);

export { db, auth, storage, getFirestore as default };
